import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import SVG from 'react-inlinesvg';
import { Typography } from '@material-ui/core';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { toAbsoluteUrl } from '../../../_helpers';
import useWindowSize from '../../../../app/pages/dashboard/chart/WindowSize';
import { BACKEND_URL, FRONTEND_URL } from '../../../../config';

import axios from 'axios';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import BranchPickerMenu from '../../../../app/components/selectors/BranchPickerMenu';
import { useCustomTheme } from '../../../../app/hooks/useCustomTheme';

export function Brand() {
  const uiService = useHtmlClassService();
  const { shop, shopSetting, user } = useSelector(state => state.auth);
  const size = useWindowSize();

  const intl = useIntl();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses('brand', true),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, 'aside.self.minimize.toggle'),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
      asideMinimize: uiService.getClasses('aside-minimize')
    };
  }, [uiService]);

  // const previewTheme = JSON.parse(window.localStorage.getItem('THEME_PREVIEW'));
  // const previewNav = previewTheme?.navColor;
  // const isPreview = previewNav ? true : false;

  // const initStyle = {
  //   background: '#1e1e2d'
  // };

  // const [background, setBackground] = useState(initStyle);

  // useEffect(() => {
  //   const navColor = shopSetting?.backgroundColor?.navColor;
  //   const isShopSetting = navColor ? true : false;

  //   if (isPreview && previewNav) {
  //     setBackground({ background: previewNav });
  //   }
  //   if (isShopSetting && navColor) {
  //     setBackground({ background: navColor });
  //   }
  //   // else {
  //   //   setBackground(initStyle);
  //   // }
  // }, [shopSetting]);

  const customTheme = useCustomTheme();
  const style = customTheme?.sidebar;

  return (
    <>
      {/* begin::Brand */}
      <div
        style={{ height: 'auto', overflowWrap: 'anywhere', background: style?.bgColor }}
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        <div>
          {layoutProps.asideSelfMinimizeToggle && (
            <div>
              <h2 className="brand-logo" style={{ color: style?.shopNameColor, padding: '15px 0 8px' }}>
                {shop ? shop.name : ''}
              </h2>
            </div>
          )}
          {user?.loggedInBranch && (
            <h4 className="brand-logo mx-0" style={{ color: style?.shopNameColor, margin: '0 30px' }}>
              {intl.formatMessage({ id: 'branch' })} : {user.loggedInBranch.name}
              {user.type === 'customer' && <BranchPickerMenu />}
            </h4>
          )}
        </div>

        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}

            <button
              className="brand-toggle btn btn-sm px-0"
              id="kt_aside_toggle"
              style={{ display: size.width > 550 ? 'block' : 'none' }}
            >
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Angle-double-left.svg')} />
              </span>
            </button>

            {/* <button
              className="brand-toggle btn btn-sm px-0"
              id=""
              style={{ display: size.width <= 550 ? 'block' : 'none' }}
              onClick={() => {
                document.getElementById('kt_aside_mobile_toggle').click();
              }}
            >
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Angle-double-left.svg')} />
              </span>
            </button> */}

            {/* <button
              onClick={() => {
                document.getElementById('kt_aside_mobile_toggle').click();
              }}
            >
              Toggle mobile
            </button> */}
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}

const Logo = styled.img`
  width: 75px;
  height: 75px;
`;
