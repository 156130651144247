import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

export default function ProgressBar({ groups, user, intl }) {
  const groupWithPoint = groups.filter(group => group.minimumPoint > 0 && group.isMemberLevel) || [];
  const sortedGroups = groupWithPoint.sort((a, b) => a.minimumPoint - b.minimumPoint) || [];
  const userGroup = user.memberGroups.filter(group => group?.minimumPoint > 0);
  const groupIndex = sortedGroups.findIndex(group => group.name === userGroup[0]?.name);
  const nextGroup = sortedGroups[groupIndex + 1] || userGroup[0];
  const progressPoint = (user.accumulatedPoint / nextGroup?.minimumPoint) * 100;

  return (
    <>
      {groupWithPoint.length > 0 && (
        <div className="my-6">
          <div className="d-flex flex-column justify-content-center align-items-center my-4">
            <span style={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'member.card.upgrade' })}</span>
            <span style={{ fontWeight: 'semi-bold' }}>{nextGroup?.name}</span>
          </div>

          {<BorderLinearProgress variant="determinate" value={progressPoint > 100 ? 100 : progressPoint} />}
          <div className="mt-3 d-flex justify-content-center">
            <div>
              <span style={{ fontWeight: 'bold', padding: '8px' }}>
                {intl.formatMessage({ id: 'member.card.accumulated.level.points' })}
              </span>
            </div>
            <div>
              <span className="text-primary" style={{ fontWeight: 'bold', color: '' }}>
                {user.accumulatedPoint}{' '}
              </span>
              {' / '}
              <span>{nextGroup?.minimumPoint}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}));
